import { Toolbar } from 'primereact/toolbar'
import logo from '../styles/images/logo-web.png'

export default function AppHeader() {

    const start = (
        <div>
            <img className='header-logo' src={logo} height="48px" />
        </div>
    )

    const center = (
        <div>
            <h3>My Body Log</h3>
        </div>
    )

    return (
        <div className="app-header">
          <Toolbar start={start} center={center}/>
          {/* <div className=''>

          </div> */}
      </div>
    )
}