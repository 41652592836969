


export function AIStatement() {

    return (
        <div className='section-overview' style={{ margin: 20 }}>

            <div className="section-paragraph">
                <h2>Artificial Intelligence </h2>
                <p>By leveraging AI, we provide real-time insights and uncover hidden patterns, empowering users to make informed decisions about their health.</p>
                <p>An AI-powered chatbot enables users to inquire about their personal history and health..</p> 
            </div>

        </div>
    )
}