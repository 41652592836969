import { Carousel, CarouselResponsiveOption } from 'primereact/carousel';
import { useState } from 'react';

export interface AppFeatureProps {
    image: string;
    heading: string;
    text: string;
    className?: string;
    style?: React.CSSProperties;
}
export function AppFeature(props: AppFeatureProps) {







    return (
        <div className={`app-feature ${props.className ?? ''}`} style={props.style} >
            <h3><b>{props.heading}</b></h3>
            <p>{props.text}</p>
            {props.image ? <img src={props.image} className="app-screen-image" /> : undefined}
        </div>

    )
}