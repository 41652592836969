


export function Security() {

    return (
        <div className='section-overview' style={{ margin: 20 }}>

            <div className="section-paragraph">
                <h2>Configuration and Security</h2>
                <p>As each person's health journey is different, all the key tracking areas will be customizable to fit each users needs.</p>
                <p>High priority will be place on data and personal information security.</p>
                <p>The data will be exportable for further analysis.</p> 
            </div>

        </div>
    )
}