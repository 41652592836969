import { CSSProperties } from "react"


export function Overview() {

    const style: CSSProperties = {
       
        
    }


    return (
        <div className='section-overview' style={{ margin: 20 }}>

            <div className="section-paragraph">
                <p>For individuals facing ongoing medical challenges, navigating through numerous treatments, diets, and remedies can feel overwhelming.</p>
                <p>Traditional approaches often leave crucial questions unanswered: What truly impacts our well-being?</p> 
                <p>Which foods, activities, and environments actually make a difference?</p>
            </div>

        </div>
    )
}