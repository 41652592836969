import './App.scss';
import 'primeflex/primeflex.css';
import { Card } from './components/card';
import AppHeader from './components/header';
import { Overview } from './documents/overview';
import OverviewImage from './documents/overview-image';
import { CoreStatement } from './documents/core-statement';
import screen1 from './documents/images/Screen1.png';
import screen2 from './documents/images/Screen2.png';
import screen3 from './documents/images/Screen3.png';
import screen4 from './documents/images/Screen4.png';
import screen5 from './documents/images/Screen5.png';
import screen6 from './documents/images/Screen6.png';
import screen7 from './documents/images/Screen7.png';
import { AppFeature } from './documents/app-feature';
import { SummaryOverview } from './documents/app-summary';
import { Security } from './documents/security';
import { ContactUs } from './documents/contact-us';
import { AIStatement } from './documents/ai-statement';

function App() {
  return (
    <div className="App">
      <AppHeader />
      <div className="grid scroll-panel">
        <Card animation="fade-right" className="col-12" >
          <h1>Discover the Power of Personalized Health Insights</h1>
        </Card>
        <Card animation="fade-right" className="lg:col-6 md:col-12 col-12" ><Overview /></Card>
        <Card animation="fade-left" className="lg:col-6 md:col-12 col-12" ><OverviewImage /></Card>

        <Card animation="zoom-in" className="col-12" ><CoreStatement /></Card>
        <Card animation="fade-in" className="col-12 section-header" >
          <h3>This innovative application is designed to help users comprehensively track seven key areas of their health.</h3>
        </Card>
        <Card animation="fade-in" className="lg:col-3 md:col-6 col-12" >
          <AppFeature image={screen1} heading='Body Observations' text="From physical discomfort like back or knee pain to emotional states such as anxiety or stress, users can record how their body feels on any given time." />
        </Card>
        <Card animation="fade-in" className="lg:col-3 md:col-6 col-12" >
          <AppFeature image={screen2} heading='Diet' text="Track food intake, focusing not on calories or carbs, but on ingredients that may be triggering unwanted reactions." />
        </Card>
        <Card animation="fade-in" className="lg:col-3 md:col-6 col-12" >
          <AppFeature image={screen3} heading='Medicines and Supplements' text="Log medications and supplements, enabling users to understand their actual impact over time" />
        </Card>
        <Card animation="fade-in" className="lg:col-3 md:col-6 col-12" >
          <AppFeature image={screen4} heading='Environmental' text="Record external conditions like pollution, pollen, and daily stress." />
        </Card>
        <Card animation="fade-in" className="lg:col-3 md:col-6 col-12" >
          <AppFeature image={screen5} heading='Vital Signs' text="Monitor essential data such as heart rate, blood pressure, and weight to provide a clearer picture of overall health." />
        </Card>
        <Card animation="fade-in" className="lg:col-3 md:col-6 col-12" >
          <AppFeature image={screen6} heading='Activities' text="Capture your daily activities, whether it's physical exercise, meditation, or therapy, and analyze their influence on your well-being." />
        </Card>
        <Card animation="fade-in" className="lg:col-3 md:col-6 col-12" >
          <AppFeature image={screen7} heading='Notes' text="Capture notes, thoughts changes to food that will be useful on your journey." />
        </Card>
        <Card animation="fade-in" className="col-12" >
          <SummaryOverview />
        </Card>
        <Card animation="fade-in" className="col-6" >
          <AIStatement />
        </Card>
        <Card animation="fade-in" className="col-6" >
          <Security />
        </Card>
        <Card animation="fade-in" className="col-12" >
          <ContactUs />
        </Card>




      </div>
    </div>
  )
}

export default App;
