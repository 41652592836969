import logo from './../styles/images/ownerlogo.svg'


export function ContactUs() {

    return (
        <div className='section-overview' style={{ margin: 20 }}>

            <div className="section-paragraph">
                <h2>Contact Us</h2>
                <p>If you are interested in joining us on this journey be it as an investor, advisor or early adaptor.</p>
                <p>Please contact me at <a href="mailto:anton.degruchy@liminil.net">Anton de Gruchy</a></p>

                <img src={logo} style={{ width: 200 }} />
                <p>My Body Log is part of LIMINIL (Pty) Ltd</p>
            </div>


        </div>
    )
}