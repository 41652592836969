import summary from '../documents/images/summary_history.png'

interface ScreenItem {
    id: number;
    name: string;
    image: string;
}

export function SummaryOverview() {


    return (
        <div id="app-images" className="section-paragraph" >
            <h3>Trend Analysis</h3>
            <p>The application empowers users to visualize and identify trends, spikes, or recurring health issues by analyzing the data they input.</p>
            <p>This data is then used to offering tailored insights and predictions on what might be triggering negative reactions.</p>
            <p>3 different views that work together to allow the user to gain an understanding of their health</p>
            <img src={summary} id="section1-image" className="section-wide-image-lg " />
            <p>The Summary screen shows aggregated data per given time period.</p>
            <p>Then you can click though to a detailed summary of a given item.</p>
            <p>That then allows you to navigate back to your daily history.</p>
            <p>This gives a user the ability to see what happened before or after a trigger.</p>
        </div>

    )
}