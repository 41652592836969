

export function CoreStatement() {



    return (
        <div id="section1" className='section-paragraph' style={{margin: 20}}>
             <h2>Mission</h2>
            <p>At the core of our mission is a focus on providing clarity and confidence.</p> 
            <p> Whether it's identifying dietary triggers such as gluten, nightshades, or grains, or understanding the nuanced effects of activities such as sports and meditation.</p> 
            <p>Our solution aims to remove the guesswork from your health journey.</p>
            {/* <img src={logo} id="section1-image" className="section-image-lg " /> */}
        </div>

    )
}